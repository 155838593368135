export type LanguageCode = 'en' | 'nl' | 'de' | 'fr' | 'es' | 'it';

type Language = {
	code: LanguageCode;
	file: `${LanguageCode}.ts`;
};

const languages: Language[] = [
	{
		code: 'en',
		file: 'en.ts',
	},
	{
		code: 'nl',
		file: 'nl.ts',
	},
	{
		code: 'de',
		file: 'de.ts',
	},
	{
		code: 'fr',
		file: 'fr.ts',
	},
	{
		code: 'es',
		file: 'es.ts',
	},
	{
		code: 'it',
		file: 'it.ts',
	},
];

export default languages;
